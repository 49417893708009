import { createClient } from "@sanity/client";

const client = createClient({
  // projectId: process.env.SANITY_PROJECT_ID ? process.env.SANITY_PROJECT_ID :'x9sofb75',
  // dataset: process.env.SANITY_DATASET ? process.env.SANITY_DATASET :'production',
  // apiVersion: '2023-03-27',
  // token:process.env.SANITY_API_TOKEN ? process.env.SANITY_API_TOKEN :'sksWt2MHKl6ZV2kPny6onpGZ4y9o4ze4URZDAyjUMg6c3YUBONAVxr6cF4AmDbq0ye3YtApuyijxHWHDlimSmnA9zISHFZ350Y4FhhWBLD5hQ7Wz1K0feW8aE1jn5DRaWDbaUSTUeH5ngduAfD8tTkXsgzVFtWCuZtqQMNGTkL1tKNY1UcIW',
  projectId: "x9sofb75", //real data
  dataset: "production",
  // useCdn: false,
  apiVersion: "2024-01-01",
  token:
    "skFt5RMpNpz3Ge6U8hXf6PvTfBSx0TppxjgeQHsIrK4Ze5iilGtWES0Mpo9cXZvFrulvk3ilvKjshy4pKtzittNoa9sQ50fua9A7nyo7h9Icg893qPgW5SoDbdgYygtFhuuOI0Q6TZFMx9LshRaif9RP4IwwcXA9Dv7bjp0l0cBMLSnsll7U",
  // token:
  // "skflJ0ZZx0oB1SDnJjBJZ3SfELv2tCdNMbnRnXilqupAXzetmStvkMlCFROd3rEedjCXHrPMMnKqGcG0vL6cEkesNpqMVNpwr0kqQinkZh7LYab2CL4WvVd424MPtZciloYRhqTFOhaARtDz01dOxAYZqBBuCPmSk2I5tfKN95639Svk9YLJ",
  // projectId: 'oc2nmmt3', //demo sanity
  // dataset: 'production',
  // // useCdn: false,
  // apiVersion: '2023-03-27',
  // token:
  // 'skAahAoAHFFRqeKzrmJcLUwYkPoMeQH9ZOJsENQliyWMh2v0jjqC1ucJhDQOrmW6AaVVcWVyFM7Ls1v6OVEdYx5tyomueoUxSrcSizRxn34xSUK2fTVMAi6EIMycaVqc3daMCScKTCfooLLahabgARaXswLcGe23ZfEPz9l1awRTYGoYcWe1',

  plugins: ["@sanity/auth"],
});

export default client;
